import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { ClipLoader } from "react-spinners";
import { Header } from "../../components/Header/Header";
import { request } from "../../utils/axios-utils";
import "./izmijeniNovost.css";
import Input from "@mui/joy/Input";
import Textarea from "@mui/joy/Textarea";
import { FormLabel } from "@mui/joy";

import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css"; // ES6
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";

const modules = {
  toolbar: [
    // Add other toolbar options here
    ["bold", "italic", "underline"], // toggled buttons
    [{ list: "ordered" }, { list: "bullet" }],

    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent

    // Add the image button here
    ["image"],

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting
  ],
};

const IzmijeniNovost = () => {
  const { novostId } = useParams();

  const [novostData, setNovostData] = useState({});
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState("");
  const [error, setError] = useState("");

  const generateSlug = (str) => {
    return str
      .toLowerCase()
      .replace(/[^\w ]+/g, "")
      .replace(/ +/g, "-")
      .substring(0, 100);
  };

  const handleDataChange = (event) => {
    const { name, value } = event.target;

    let updatedValue = value;
    if (name === "naslov") {
      const slug = generateSlug(value);
      setNovostData((prevData) => ({ ...prevData, slug }));
    } else if (name === "slug") {
      const updatedSlug = value
        .toLowerCase()
        .replace(/[^\w-]+/g, "") // Allow hyphens in slug
        .substring(0, 100); // Limit slug length
      setNovostData((prevData) => ({ ...prevData, slug: updatedSlug }));
      return;
    }

    setNovostData((prevData) => ({ ...prevData, [name]: updatedValue }));
  };

  const handleQuillChange = (value) => {
    setNovostData((prevData) => ({ ...prevData, content: value }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      setImage(file);
    };
    reader.readAsDataURL(file);
  };

  const izmijeniNovost = async () => {
    setError("");
    if (novostData.naslov === "") {
      setError("Dodajte naslov.");
      return;
    } else if (novostData.slug === "") {
      setError("Dodajte slug.");
      return;
    } else if (novostData.content === "") {
      setError("Dodajte content.");
      return;
    }
    if (novostData.seoNaslov === "") {
      setError("Dodajte SEO naslov.");
      return;
    }
    if (novostData.seoOpis === "") {
      setError("Dodajte SEO opis.");
      return;
    }

    try {
      setLoading("izmijeniNovost");

      const formData = new FormData();
      formData.append("image", image);
      formData.append("novostData", JSON.stringify(novostData));

      const response = await request({
        url: "/izmijeni-novost",
        method: "POST",
        data: formData,
      });

      toast.success(response.data.message);
    } catch (err) {
      setError(err.response.data.message);
    } finally {
      setLoading("");
    }
  };

  useEffect(() => {
    const getNovostData = async () => {
      try {
        setLoading("data");

        const response = await request({
          url: "/get-novost-by-id",
          method: "POST",
          data: {
            novostId,
          },
        });

        setNovostData(response.data);
      } catch (err) {
      } finally {
        setLoading("");
      }
    };

    getNovostData();
  }, []);

  return (
    <div className="page-container">
      <Header />

      <h1 className="mt-74 page-heading">Izmijeni Novost</h1>

      {loading === "data" ? (
        <ClipLoader size={40} color={"#f8ce38"} />
      ) : (
        <div className="dodaj-novost-form-div">
          <div className="picture-input-div">
            <div className={"picture-input"}>
              {image ? (
                <>
                  <img
                    className="picture-output"
                    src={URL.createObjectURL(image)}
                  />
                  <input
                    onChange={handleFileChange}
                    type="file"
                    accept="image/*"
                  />
                </>
              ) : (
                <>
                  <img
                    className="picture-output"
                    src={novostData?.image?.url}
                  />
                  <input
                    onChange={handleFileChange}
                    type="file"
                    accept="image/*"
                  />
                </>
              )}
            </div>
          </div>

          <FormLabel>Naslov</FormLabel>
          <Input
            className="dodaj-novost-input"
            name="naslov"
            value={novostData.naslov}
            onChange={handleDataChange}
            placeholder="Naslov"
          />

          <FormLabel>Slug</FormLabel>
          <Input
            className="dodaj-novost-input"
            name="slug"
            value={novostData.slug}
            onChange={handleDataChange}
            placeholder="Slug"
          />
          <FormLabel>Content</FormLabel>
          <div className="dodaj-novost-quill-div">
            <ReactQuill
              modules={modules}
              value={novostData.content}
              onChange={handleQuillChange}
            />
          </div>

          <FormLabel>SEO Naslov</FormLabel>
          <Input
            className="dodaj-novost-input"
            name="seoNaslov"
            value={novostData.seoNaslov}
            onChange={handleDataChange}
            placeholder="SEO Naslov"
          />

          <FormLabel>SEO Opis</FormLabel>
          <Textarea
            className="dodaj-novost-input"
            name="seoOpis"
            value={novostData.seoOpis}
            onChange={handleDataChange}
            placeholder="SEO Opis"
            minRows={3}
            maxRows={6}
          />

          {error && <p className="login-error-message">{error}</p>}
          <div
            onClick={loading === "izmijeniNovost" ? null : izmijeniNovost}
            className="dodaj-novost-btn"
          >
            {loading === "izmijeniNovost" ? (
              <ClipLoader size={20} color={"#fff"} />
            ) : (
              "Izmijeni Novost"
            )}
          </div>

          <Link to={"/"} className="dodaj-novost-cancel-btn">
            Odkaži
          </Link>
        </div>
      )}
    </div>
  );
};

export default IzmijeniNovost;
