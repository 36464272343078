import { useState } from "react";

let tokenStatus = null;
let setTokenStatus = () => {};

export const useTokenStatus = () => {
  const [status, setStatus] = useState(tokenStatus);
  setTokenStatus = setStatus;

  const handleInvalidToken = () => {
    setTokenStatus("invalid");
  };

  return { tokenStatus: status, handleInvalidToken };
};

export const setTokenStatusExternally = (status) => {
  setTokenStatus(status);
};
