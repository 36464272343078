import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router";
import Novosti from "./pages/Novosti/Novosti";
import { useContext } from "react";
import { AuthContext } from "./context/auth-context";
import Login from "./pages/Login/Login";
import ProtectedRoutes from "./ProtectedRoutes";
import PublicRoutes from "./PublicRoutes";
import DodajNovost from "./pages/DodajNovost/DodajNovost";
import IzmijeniNovost from "./pages/IzmijeniNovost/IzmijeniNovost";

const App = () => {
  const authCtx = useContext(AuthContext);

  if (authCtx.isLoading) {
    return <h1>Loading...</h1>;
  }

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route element={<ProtectedRoutes />}>
            <Route path="/" element={<Novosti />} />
            <Route path="/dodaj-novost" element={<DodajNovost />} />
            <Route path="/:novostId" element={<IzmijeniNovost />} />
          </Route>

          <Route element={<PublicRoutes />}>
            <Route path="/login" element={<Login />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
