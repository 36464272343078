import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "./context/auth-context";
import { useTokenStatus } from "./hooks/useTokenStatus";

export default function ProtectedRoutes() {
  const { tokenStatus } = useTokenStatus();
  const { isAuthenticated, logout } = useContext(AuthContext);

  const LogoutModal = () => {
    return (
      <div onClick={() => {}} className="modal-overlay">
        <div onClick={() => {}} className="modal-content">
          <h1 className="expire-modal-h">
            Session expired. Please log in again.
          </h1>

          <div className="modal-btns-div">
            <div
              className="modal-primary-btn"
              onClick={() => {
                logout();
                window.location.reload();
              }}
            >
              Log in
            </div>
          </div>
        </div>
      </div>
    );
  };

  return isAuthenticated ? (
    <>
      {tokenStatus === "invalid" && <LogoutModal />}
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" />
  );
}
