import React, { useEffect, useState } from "react";
import "./novosti.css";
import "animate.css";
import { Header } from "../../components/Header/Header";
import Input from "@mui/joy/Input";
import plus from "../../assets/plus.png";
import deleteIcon from "../../assets/delete.png";
import editIcon from "../../assets/edit.png";

import { Link } from "react-router-dom";
import { request } from "../../utils/axios-utils";
import { ClipLoader } from "react-spinners";
import DeleteModal from "../../components/deleteModal/DeleteModal";
import { toast } from "react-hot-toast";

const Novosti = () => {
  const [novosti, setNovosti] = useState([]);
  const [loading, setLoading] = useState("");
  const [deleteModal, setDeleteModal] = useState({
    type: "",
    id: null,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredNovosti, setFilteredNovosti] = useState([]);

  const deleteNovost = async () => {
    try {
      const response = await request({
        url: "/izbrisi-novost",
        method: "POST",
        data: { novostId: deleteModal.id },
      });

      setNovosti((prevData) =>
        prevData.filter((novost) => novost.id !== deleteModal.id)
      );

      toast.success(response.data.message);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value.toLowerCase().trim());

    const filteredNovosti = novosti.filter((novost) => {
      return (
        novost.naslov.toLowerCase().includes(searchQuery) ||
        novost.seoNaslov.toLowerCase().includes(searchQuery) ||
        novost.seoOpis.toLowerCase().includes(searchQuery) ||
        novost.slug.toLowerCase().includes(searchQuery)
      );
    });

    setFilteredNovosti(filteredNovosti);
  };

  const formatDateTime = (dateString) => {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    return new Date(dateString)
      .toLocaleString("en-US", options)
      .replace(",", "");
  };

  useEffect(() => {
    const getNovosti = async () => {
      try {
        setLoading("data");

        const response = await request({ url: "/get-novosti" });

        setNovosti(response.data.reverse());
      } catch (err) {
      } finally {
        setLoading("");
      }
    };

    getNovosti();
  }, []);

  useEffect(() => {
    setFilteredNovosti(novosti);
  }, [novosti]);

  return (
    <div className="page-container">
      {deleteModal.type === "novost" && (
        <DeleteModal
          heading={"Izbrišite novost?"}
          paragraph={"Da li ste sigurni da želite da izbrišite ovu novost?"}
          deleteFunction={deleteNovost}
          setDeleteModal={setDeleteModal}
        />
      )}

      <Header />

      <h1 className="page-heading mt-74">Novosti</h1>

      <div className="novosti-search-div">
        <Input
          onChange={handleSearch}
          value={searchQuery}
          className="novosti-search-input"
          placeholder="Pretraži"
        />

        <Link to={"/dodaj-novost"} className="dodaj-btn-div">
          <img className="dodaj-btn-img" src={plus} />
          <p className="dodaj-btn-p">Dodaj Novost</p>
        </Link>
      </div>

      {loading === "data" ? (
        <ClipLoader size={40} color={"#f8ce38"} />
      ) : (
        <div className="novosti-div">
          {filteredNovosti?.map((novost) => {
            return (
              <div
                key={novost.id}
                className="animate__animated animate__fadeInUp novost-div"
              >
                <img src={novost.image.url} className="novost-img" />

                <div className="novost-text-div">
                  <p className="novost-time-p">
                    Pregledi: {novost.viewsCount || 0}
                  </p>
                  <h3 className="novost-heading">{novost.seoNaslov}</h3>
                  <p className="novost-time-p">
                    {formatDateTime(novost?.timestamp)}
                  </p>
                  <p className="novost-p">{novost?.seoOpis}</p>
                </div>

                <div className="novost-btns-div">
                  <Link to={`/${novost.id}`} className="novost-btn-div">
                    <img src={editIcon} className="novost-btn-img" />
                  </Link>

                  <div
                    onClick={() => {
                      setDeleteModal({
                        type: "novost",
                        id: novost.id,
                      });
                    }}
                    className="novost-btn-div"
                  >
                    <img src={deleteIcon} className="novost-btn-img" />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Novosti;
