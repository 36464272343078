import axios from "axios";
import { setTokenStatusExternally } from "../hooks/useTokenStatus";

const client = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});
const token = window.localStorage.getItem("token");

export const request = ({ ...options }) => {
  client.defaults.headers.common.Authorization = `Bearer ${token}`;

  const onSuccess = (response) => response;
  const onError = (error) => {
    if (error.response && error.response.status === 403) {
      setTokenStatusExternally("invalid");
    }

    throw error;
  };

  // Check if the request contains form data
  const isFormData = options.data instanceof FormData;

  if (!isFormData) {
    return client(options).then(onSuccess).catch(onError);
  }

  // If it's form data, use axios directly
  const axiosOptions = {
    method: options.method,
    url: `${process.env.REACT_APP_SERVER_URL}${options.url}`,
    data: options.data,
    headers: {
      ...options.headers,
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };

  return axios(axiosOptions).then(onSuccess).catch(onError);
};
