import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { ClipLoader } from "react-spinners";
import "./deleteModal.css";

const DeleteModal = ({
  heading,
  paragraph,
  deleteFunction,
  setDeleteModal,
  isStop,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const btnText = isStop ? "Stop" : "Izbriši";

  const handleModalContentClick = (event) => {
    event.stopPropagation();
  };

  const closeModal = () => {
    setDeleteModal({ type: "", id: null });
  };

  const handler = async () => {
    try {
      setIsLoading(true);
      await deleteFunction();
      setDeleteModal({ type: "", id: null });
    } catch (err) {
      toast.error(err?.response?.data?.error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div onClick={closeModal} className="modal-overlay">
      <div
        onClick={handleModalContentClick}
        className="delete-modal-content animate__animated animate__zoomIn animate__faster"
      >
        <h1 className="modal-h">{heading}</h1>
        <p className="delete-modal-p">{paragraph}</p>

        <div className="delete-modal-btns-div">
          <div className="modal-secondary-btn" onClick={closeModal}>
            Odustani
          </div>
          <div
            className="delete-modal-primary-btn"
            onClick={isLoading === true ? null : handler}
          >
            {isLoading ? <ClipLoader size={20} color={"#ffffff"} /> : btnText}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
