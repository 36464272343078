import React, { useContext, useState } from "react";
import "./login.css";
import logo from "../../assets/logo.png";
import Input from "@mui/joy/Input";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { AuthContext } from "../../context/auth-context";

const Login = () => {
  const { authenticate } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async () => {
    try {
      setLoading(true);
      setError("");

      if (!email) {
        setError("Unesite email adresu.");
      } else if (!password) {
        setError("Unesite password.");
      }

      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/login`,
        {
          email: email,
          password: password,
        }
      );

      authenticate(response.data.token);
      window.location.reload();
    } catch (error) {
      setError(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-form-div">
        <div className="login-form-helper-div">
          <img src={logo} className="login-form-logo" />

          <Input
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            className="login-form-input"
            placeholder="Email"
            type={"email"}
            variant="soft"
            size="md"
          />

          <Input
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            className="login-form-input"
            placeholder="Password"
            type={"password"}
            variant="soft"
            size="md"
          />

          {error && <p className="login-error-message">{error}</p>}
        </div>

        <div onClick={handleLogin} className="login-form-btn">
          {loading ? <ClipLoader size={20} color={"#fff"} /> : "Login"}
        </div>
      </div>
    </div>
  );
};

export default Login;
