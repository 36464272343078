import React from "react";
import logo from "../../assets/logo.png";
import "./header.css";

export const Header = () => {
  return (
    <div className="header-div">
      <img className="header-img" src={logo} />
    </div>
  );
};
